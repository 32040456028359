body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F5F5F5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.CardCurrencyWrapper {
  display: flex;
  align-items: center;
}
.CardCurrencyWrapper h4 {
  margin: 0;
  margin-bottom: 10px;
}
.CardCurrencyWrapper p {
  margin: 0;
}

.leftSection {
  border-right: 1px solid #ddd;
}
.InputCurrencyWrapper {
  position: fixed;
  padding: 15px 15px 15px 0;
  background: -webkit-gradient(linear,left top, left bottom,from(#f53d2d),to(#f63));
  background: -webkit-linear-gradient(top,#f53d2d,#f63);
  background: linear-gradient(-180deg,#f53d2d,#f63);
  color: white;
  box-shadow: 0 1px 1px 0 rgba(0,0,0,.05);
  z-index: 1;
}

.CardCurrencyWrapper {
  padding-top: 105px;
}

.CardCurrencyList {
  margin-bottom: 15px;
}

.ButtonAddCurrencyWrapper {
  margin-bottom: 15px;
}
