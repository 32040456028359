.InputCurrencyWrapper {
  position: fixed;
  padding: 15px 15px 15px 0;
  background: linear-gradient(-180deg,#f53d2d,#f63);
  color: white;
  box-shadow: 0 1px 1px 0 rgba(0,0,0,.05);
  z-index: 1;
}

.CardCurrencyWrapper {
  padding-top: 105px;
}

.CardCurrencyList {
  margin-bottom: 15px;
}

.ButtonAddCurrencyWrapper {
  margin-bottom: 15px;
}