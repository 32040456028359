.CardCurrencyWrapper {
  display: flex;
  align-items: center;
}
.CardCurrencyWrapper h4 {
  margin: 0;
  margin-bottom: 10px;
}
.CardCurrencyWrapper p {
  margin: 0;
}

.leftSection {
  border-right: 1px solid #ddd;
}